import React, { useState } from "react"
import { Link } from "gatsby"
import "./blog.css"
import useFetch from "../../utils/fetch"
import Gallery from "../Gallery/gallery"
import CustomGallery from "../CustomGallery/CustomGallery"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Circles } from "react-loader-spinner"
import { NewsletterSubscription } from "../Newsletter/NewsletterSubscription"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons"
const qs = require("qs")

const BlogSideBarLeft = props => {
  const { postTags, postCategories, blogpostGallery } = props

  // initial state
  const [showRecentPosts, setShowRecentPosts] = useState(false)

  const query = qs.stringify(
    {
      pagination: {
        limit: 5,
      },
      sort: ["date:desc"],
      populate: ["FeatureImage"],
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  )
  const urlRequest = `${process.env.STRAPI_ENDPOINT}/posts?${query}`
  const { loading, error, strapi_response } = useFetch(urlRequest)


  if (loading)
    return (
      <div className="spinner">
        <Circles color="#00BFFF" height={80} width={80} />
      </div>
    )
  if (error) return <p>Eroare....{error}</p>

  // format gallery in the required format of the Gallery component
  const formattedGallery = blogpostGallery.data !== null ?
    blogpostGallery.data.map((el => {
      return {
        src: `${process.env.STRAPI_BACKEND}${el.attributes.url}`,
        original: `${process.env.STRAPI_BACKEND}${el.attributes.url}`,
        alt: el.attributes.alternativeText,
        width: 248,
        height: 320,
        caption: el.attributes.caption,
      }
    })) : null

console.log('formattedGallery --- ', formattedGallery)

  return (
    <div className="col-lg-3 fix-header-overlap">
      <aside className="side-bar sticky-top">
        <div className="widget">
          <h5 style={{ fontWeight: 700 }} className="widget-title style-1">
            Cauta
          </h5>

          <div className="search-bx style-1">
            <form role="search" method="GET" action="/cercetare">
              <div className="input-group">
                <input
                  name="keyword"
                  className="form-control"
                  placeholder="Scrie cuvinte cheie..."
                  type="text"
                  id="search-keywords"
                />
                <span className="input-group-btn">
                  <button type="submit" className="fa fa-search text-primary" />
                </span>
              </div>
            </form>
          </div>
        </div>

        {/* Recent Posts */}
        <div className="widget recent-posts-entry th-recent-posts__wrapper">
          <h5 className="widget-title style-1 recent-posts-header">POSTARI RECENTE <span className="show-on-responsive" onClick={() => setShowRecentPosts(!showRecentPosts)}><FontAwesomeIcon icon={!showRecentPosts ? faChevronRight : faChevronDown} /></span></h5>
          <div className={"widget-post-bx " + (!showRecentPosts ? 'hide-recent-posts' : '')} >
            {strapi_response?.data.map((item, key) => (
              <BlogRecentPost
                item={item}
                key={key}
                img={
                  <img
                    src={
                      require("../../images/blog/recent-blog/pic1.jpg").default
                    }
                    width="200"
                    height="143"
                    alt=""
                  />
                }
              />
            ))}
          </div>
        </div>
        <NewsletterSubscription subscribing={true} />

        {/* Post's Gallery */}
        {formattedGallery !== null ? <CustomGallery images={formattedGallery}></CustomGallery> : null}

        <div className="widget widget_archive margin-zero">
          <h5 className="widget-title style-1">LISTA DE CATEGORII</h5>
          <ul>
            {postCategories?.map((item, key) => {
              return (
                <li key={key}>
                  <Link
                    to={`/cercetare?category=${item.attributes.categorySlug}`}
                  >
                    {item.attributes.categoryName}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>

        <div className="widget widget_tag_cloud radius">
          <h5 className="widget-title style-1">TAGURI</h5>
          <div className="tagcloud">
            {postTags?.map((tag, key) => (
              <Link to={`/cercetare?tag=${tag.attributes.tagSlug}`} key={key}>
                {tag.attributes.tagName}
              </Link>
            ))}
          </div>
        </div>
      </aside>
    </div>
  )
}

export const BlogRecentPost = ({ img, item }) => {
  const current = new Date(item.attributes.date)
  let month = current.toLocaleString("default", { month: "long" })
  const date = `${current.getDate()} ${month}`
  const postImg =
    `${process.env.STRAPI_BACKEND}` +
    item.attributes.FeatureImage.data.attributes.formats.thumbnail.url

  const title = item?.attributes?.Title.length > 40 ? item?.attributes?.Title?.substring(0, 39) + '...' : item?.attributes?.Title
  // console.log('title --- ', title)
  return (
    <div className="widget-post clearfix blog-recent-post">
      <div className="dlab-post-media">
        <img
          src={`${postImg}`}
          alt={`Articol Recent - ${item?.attributes?.Title}`}
        />
      </div>
      <div className="dlab-post-info">
        <div className="dlab-post-meta">
          <ul>
            <li className="post-date">
              <strong>{date}</strong>
            </li>
            <li className="post-author">By {item?.attributes?.author}</li>
          </ul>
        </div>

        <div className="dlab-post-header">
          <h6 className="post-title">
            <Link to={`/cercetare/${item?.attributes?.postSlug}`}>
              <p>{title}</p>
            </Link>
          </h6>
        </div>
      </div>
    </div>
  )
}

export default BlogSideBarLeft
