import React, { useState } from "react"
import "viewerjs/dist/viewer.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons"

import "./wsp-gallery.css"

function Gallery({ blogpostGallery }) {
  const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = index => {
    setSlideNumber(index)
    if (window != "undefined") {
      // fix for header (hide header)
      document.getElementsByClassName("sticky-inner-wrapper")[0].style.zIndex =
        "-1"
      // fix for blogpost sidebar (overlapping elements)
      document.querySelector(".side-bar.sticky-top").style.zIndex = "999999"
    }

    setOpenModal(true)
  }

  // Close Modal
  const handleCloseModal = () => {
    if (window != "undefined") {
      // fix for header (make header visible)
      document.getElementsByClassName("sticky-inner-wrapper")[0].style.zIndex =
        "999999"
      // fix for blogpost sidebar (overlapping elements)
      document.querySelector(".side-bar.sticky-top").style.zIndex = "initial"
    }
    setOpenModal(false)
  }

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(blogpostGallery.length - 1)
      : setSlideNumber(slideNumber - 1)
  }

  // Next Image
  const nextSlide = () => {
    slideNumber + 1 === blogpostGallery.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1)
  }

  return (
    <div className="widget widget_gallery gallery-grid-4">
      <h5 className="widget-title style-1">Galerie</h5>
      {/* Gallery Modal Layout */}
      {openModal && (
        <div className="sliderWrap">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="btnClose"
            onClick={handleCloseModal}
          />
          <FontAwesomeIcon
            icon={faCircleChevronLeft}
            className="btnPrev"
            onClick={prevSlide}
          />
          <FontAwesomeIcon
            icon={faCircleChevronRight}
            className="btnNext"
            onClick={nextSlide}
          />
          <div className="fullScreenImage">
            <img
              src={`${process.env.STRAPI_BACKEND}${blogpostGallery[slideNumber].attributes.url}`}
              alt=""
            />
          </div>
        </div>
      )}

      <div className="galleryWrap">
        <ul id="lightgallery" className="lightgallery">
          {blogpostGallery &&
            blogpostGallery?.map((item, index) => {
              return (
                <li
                  className="single"
                  key={index}
                  onClick={() => handleOpenModal(index)}
                >
                  <div className="dlab-post-thum dlab-img-effect">
                    <span
                      data-exthumbimage={`${process.env.STRAPI_BACKEND}${item.attributes.url}`}
                      data-src={`${process.env.STRAPI_BACKEND}${item.attributes.url}`}
                      className="check-km"
                      title={item.attributes.alternativeText}
                    >
                      <img
                        src={`${process.env.STRAPI_BACKEND}${item.attributes.url}`}
                        alt={item.attributes.alternativeText}
                      />
                    </span>
                  </div>
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}

export default Gallery
