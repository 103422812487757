import React from "react"
import { Link } from "gatsby"
import customIsUrl from "../../utils/customIsUrl"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks from "remark-breaks"

const BlogSingleContent = ({ strapi_response }) => {
  const Title = strapi_response?.data[0]?.attributes?.Title
  const ShortDescription =
    strapi_response?.data[0]?.attributes?.ShortDescription
  const Content = strapi_response?.data[0]?.attributes?.Content
  const author = strapi_response?.data[0]?.attributes?.author
  const imgUrl =
    strapi_response?.data[0]?.attributes.FeatureImage.data.attributes.url
  const FeatureImage = `${process.env.STRAPI_BACKEND}${imgUrl}`

  const current = new Date(strapi_response?.data[0]?.attributes?.date)
  const month = current.toLocaleString("default", { month: "long" })
  const year = current.getFullYear()

  const date = `${current.getDate()} ${month}`

  const videos = strapi_response?.data[0]?.attributes?.videoURLs?.values
  const filteredVideosURLs = videos?.filter((video) => customIsUrl(video))

  console.log('videos --- ', videos)
  console.log('filteredVideosURLs --- ', filteredVideosURLs)

  return (
    <div className="col-lg-9 m-b30">
      <div className="blog-post blog-single">
        <div className="dlab-post-meta">
          <ul>
            <li className="post-date">
              <strong style={{ fontWeight: 700 }}>{date}</strong>{" "}
              <span style={{ fontWeight: 700 }}> {year}</span>{" "}
            </li>
            <li className="post-author" style={{ fontWeight: 700 }}>
              By <Link to="/#">{author}</Link>
            </li>
          </ul>

          <p style={{ padding: 0, margin: 0 }}>{Title}</p>
        </div>
        <div className="dlab-post-title ">
          <h4 className="post-title m-t0">
            <>
              <Link
                to="#"
                style={{ fontWeight: 700 }}
              ></Link>
            </>
          </h4>
        </div>
        <div className="dlab-post-media dlab-img-effect zoom-slow">
          <Link to="#">
            <img src={`${FeatureImage}`} alt="" />
          </Link>
        </div>
        <div className="dlab-post-text">
          <div className="post-short-description">
            <ReactMarkdown children={ShortDescription} remarkPlugins={[remarkGfm, remarkBreaks]} />
          </div>

          <img
            className="alignleft hide-on-responsive small-post-feature-image"
            width="300"
            src={`${FeatureImage}`}
            alt=""
          />
          {/* Content */}
          <div className="post-description">
            <ReactMarkdown children={Content} remarkPlugins={[remarkGfm, remarkBreaks]} />
          </div>


          {/* Additional Content - Videos etc. */}
          {/* Videos */}
          <div className="videos-wrapper">
            {filteredVideosURLs ? filteredVideosURLs.map((videoUrl, index) => (
              <video controls key={index}>
                <source src={videoUrl} type="video/mp4" />
                <source src={videoUrl} type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            )) : null}
          </div>

        </div>
      </div>
    </div>
  )
}

export default BlogSingleContent
