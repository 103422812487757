import React from "react"

import BlogSideBarLeft from "./BlogSideBarLeft"
import BlogSingleContent from "./BlogSingleContent"

import Layout from "../../markup/layout/layout"
import Seo from "../../markup/layout/seo"
import useFetch from "../../utils/fetch"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Circles } from "react-loader-spinner"
import { PageBanner } from "../../markup/layout/PageBanner"

const qs = require("qs")

const BlogPost = props => {
  const { slug } = props

  const query = qs.stringify(
    {
      filters: {
        postSlug: {
          $eq: slug,
        },
      },
      populate: ["FeatureImage", "tags", "categories", "Gallery"],
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  )

  const urlRequest = `${process.env.STRAPI_ENDPOINT}/posts?${query}`
  const { loading, error, strapi_response } = useFetch(urlRequest)

  const postTags = strapi_response?.data[0]?.attributes.tags.data
  const postCategories = strapi_response?.data[0]?.attributes.categories.data
  const blogpostGallery = strapi_response?.data[0]?.attributes.Gallery

  if (loading)
    return (
      <div className="spinner">
        <Circles color="#00BFFF" height={80} width={80} />
      </div>
    )
  const noBlogPostFound =
    strapi_response.data.length === 0 ? true : false
  if (error || strapi_response.data.length === 0)
    return (
      <p>
        Eroare....{error}
        <br />
        {noBlogPostFound && <h2>Pagina nu exista</h2>}
      </p>
    )

  const Title = strapi_response?.data[0]?.attributes?.Title
  return (
    <Layout>
      <Seo title={Title} />
      <PageBanner
        isHomePage={false}
        pageInfo={{
          title: Title,
          parent: { title: "Cercetare", slug: "/cercetare" },
          hasParent: true,
        }}
      />
      <div className="bg-white">
        <div className="content-area">
          <div className="container">
            <div className="row th-single-post">
              <BlogSideBarLeft
                postTags={postTags}
                postCategories={postCategories}
                blogpostGallery={blogpostGallery}
              />
              <BlogSingleContent
                slug={slug}
                strapi_response={strapi_response}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost
